<template>
    <div id="calculations-page">
       
        <vs-alert v-if="!this.can_see_page" color="warning" :title="$t('AccessDeniedTitle')" active="true">
          <p>{{ $t(this.AccessDeniedMessage) }}<br>[{{ this.AccessDeniedInfos }}]</p>
        </vs-alert>

        <template v-else>
          <h4 class="mb-2">{{ $t('calculationsCreate') }}</h4>
          <vx-card v-for="calculation in calculations" :key="calculation.title">
            <a :href="calculation.page">
              <h4>
                {{calculation.title}}
              </h4>
              <p>
                {{ $t(calculation.description) }}
              </p>
            </a>
          </vx-card>
          <div class="mt-6" v-if="queue !== null" :key="pageIncrement">
            <vs-table v-model="selectedCalculation" stripe pagination :max-items="10" search :data="queue">
                <template slot="header">
                  <h4>{{ $t('calculationsLog') }}</h4>
                </template>
                <template slot="thead">
                  <vs-th class="pointer-events-none">{{$t('ID')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('User')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('Date')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('layer_type')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('Layers')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('Status')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('Output')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('Actions')}}</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>#{{ tr.uuid.substr(tr.uuid.length - 6) }}</vs-td>
                    <vs-td>{{ tr.user.first_name }}<br>{{ tr.user.last_name }}</vs-td>
                    <vs-td>{{ tr.sql_timestamp | longDate }}</vs-td>
                    <vs-td>{{ tr.calc_type }}</vs-td>
                    <vs-td>
                      <div class="flex row w-full">
                        <div class="col w-2/5">
                          <div><strong>{{ tr.inputs[0].layer_name }}</strong></div>
                          <div>{{ tr.inputs[0].layer_type }}</div>
                          <div><i><a target="_blank" :href="`/project/${tr.inputs[0].project_slug}`" >{{ tr.inputs[0].project_nicename }}</a></i></div>
                        </div>
                        <div class="col w-1/5 text-center mt-6">
                          <feather-icon icon="PlusIcon"/>
                        </div>
                        <div class="col w-2/5">
                          <div><strong>{{ tr.inputs[1].layer_name }}</strong></div>
                          <div>{{ tr.inputs[1].layer_type }}</div>
                          <div><i><a target="_blank" :href="`/project/${tr.inputs[1].project_slug}`" >{{ tr.inputs[1].project_nicename }}</a></i></div>
                        </div>
                      </div>
                    </vs-td>
                    <vs-td>{{ tr.status }}</vs-td>
                    <vs-td>{{ tr.output }}</vs-td>
                    <vs-td>
                      <vs-button v-if="tr.status !== 'success' && tr.status !== 'error' && tr.status !== 'canceled'"
                        @click.stop="cancelJob(tr.uuid)"
                        color="danger"
                        size="small"
                        type="border"
                        icon-pack="feather"
                        icon="icon-x" />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              
            <!-- <pre>{{ queue }}</pre> -->
          </div>
        </template>
    </div>
</template>
<script>
import axios from '@/axios.js'
import moment from 'moment' // for date comparaison

// import logActivity from '@/logActivity.js'

export default {
  components: {
  },
  data () {
    return {
      // ROYBON / API calls
      can_see_page: null,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      queue: [],
      timer: null,
      selectedCalculation: null,
      calculations: [
        {
          title: 'MNT_DIFF',
          page: `/${this.$store.state.portal_slug}/calculations/mnt-diff`,
          description: 'MntDiffDescription'
        }
      ],
      pageIncrement: 1
    }
  },
  methods: {    
    // ROYBON / Lit la file d'attente
    getQueue () {
      const rimnat_api_call_url = `${this.api_server_baseurl}/calculation/getQueue?portal_id=${this.$store.state.portal_id}`
      axios.get(rimnat_api_call_url)
        .then((response) => {
          this.queue = response.data
        })
        .catch((error) => { console.error(error) })
    },

    // ROYBON / Lit la file d'attente
    cancelJob (uuid) {
      const rimnat_api_call_url = `${this.api_server_baseurl}/calculation/updateJob`
      const params = new FormData()
      params.set('uuid', uuid)
      params.set('status', 'canceled')
      params.set('output', `canceled from this page at ${moment().format('DD/MM/YYYY HH:mm')}`)
      axios.post(rimnat_api_call_url, params)
        .then(() => {
          this.getQueue()
        })
        .catch((error) => { console.error(error) })
    },

    // ROYBON / Check si l'affichage de la page est autorisé
    canSeePage () {
      const gc =                this.$store.state.current_user_global_caps
      const poc =               this.$store.state.current_user_portal_caps
      const poc_start_date =    poc.can_portal_access_start_date
      const poc_end_date =      poc.can_portal_access_end_date
      if (gc.can_global_access === '1' && poc.can_portal_access === '1') {
        if (poc_start_date !== null && moment().isSameOrBefore(poc_start_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessNotYetOpened'
          this.AccessDeniedInfos = poc_start_date
        } else if (poc_end_date !== null && moment().subtract(1, 'd').isSameOrAfter(poc_end_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessExpired'
          this.AccessDeniedInfos = poc_end_date
        } else if (poc.can_portal_layers_manage !== '1') {
          this.can_see_page = false
          this.AccessDeniedMessage = 'cannotManageLayers'
        } else {
          this.can_see_page = true
        }
      } else {
        this.can_see_page = false
        this.AccessDeniedMessage = 'PortalAccessDeniedFull' 
        this.AccessDeniedInfos = 'global or portal access invalid'
      }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },

  computed: {
  },

  beforeMount () {
    this.canSeePage()
  },
  
  created () {
  },
  
  mounted () {
    this.isMounted = true
    this.getQueue()
    this.timer = setInterval(this.getQueue, 30000)
  },

  watch: {
    queue: function () {
      this.pageIncrement += 1
    }
  }
}
</script>
